<template>
	<div class="MeterialBox" v-loading='loading'>
		<div class="MeterialContain">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">发布时间:</label>
					<el-date-picker v-model="addTimes" type="datetimerange" :picker-options="pickerOptions" range-separator="～"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="filter-item">
					<label class="label">商品编码: </label>
					<el-input v-model="goodsCode" placeholder="商品编码" style="width:250px;margin-right: 10px;" clearable
					@keyup.enter.native="goodsFilter"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">商品名称: </label>
					<el-input v-model="goodsName" placeholder="商品名称" style="width:250px;margin-right: 10px;" clearable
					@keyup.enter.native="goodsFilter"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">商品分组:</label>
					<el-select v-model="groupId" filterable clearable style="width: 200px;margin-right: 10px;">
						<el-option v-for="item in goodGroupData" :key="item.groupId" :label="item.groupName" :value="item.groupId">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">供货商名称:</label>
					<el-select v-model="supplierId" filterable clearable style="width: 200px;margin-right: 10px;">
						<el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item.supplierName">
						</el-option>
					</el-select>
				</div>
				<el-button type='primary' @click='goodsFilter'>查询</el-button>
				<buttonPermissions :datas="'addMeterail'">
					<el-button type='primary' style="margin-left: 20px;" @click='addMeterail({id:0},0)'>发布素材</el-button>
				</buttonPermissions>
			</div>

			<!-- 表格 -->
			<div class="table-container">
				<el-table :data="MeterialData" :row-key="getRowKeys" @selection-change="handleSelectionChange" ref="tab">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="materialTypeStr" label="素材类型"></el-table-column>
					<el-table-column prop="context" width="340" label="发布内容"></el-table-column>
					<el-table-column prop="likeNum" label="点赞数"></el-table-column>
					<el-table-column prop="forwardNum" label="转发数"></el-table-column>
					<el-table-column prop="viewNum" label="查看数"></el-table-column>
					<el-table-column prop="goodsName" width="340" label="商品名称"></el-table-column>
					<el-table-column prop="createTime" width="180" label="创建时间"></el-table-column>
					<el-table-column label="操作" width="240">
						<template slot-scope="scope">
							<buttonPermissions :datas="'addMeterail'">
								<el-button type="text" @click="addMeterail(scope.row,1)">查看详情</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'editMeterail'">
								<el-button style="margin-left:10px;" type="text" @click="addMeterail(scope.row,2)">编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'delMeterail'">
								<span style="color:#f00;margin-left:10px;cursor: pointer;" @click="deleteChose(scope.row,1)">删除</span>
							</buttonPermissions>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						<el-checkbox @change="tabCheckedChange" v-model="checked">当前页全选</el-checkbox>
						<el-button style="margin-left: 20px;" type="text" @click="deleteChose({},2)">批量删除</el-button>
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="Total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import config from '@/config/index'
	import {
		productGroupListData,
		supplierGetList,
		findAllMaterial,
		delMaterial
	} from '@/api/goods'
	export default {
		components: {
			buttonPermissions,
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				InitInfo: {},
				ProductId: 0,
				searchKey: '',
				loading: false,
				MeterialData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				addTimes: '',
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				groupId: '', //商品分组
				supplierId: '', //商品供应商id
				goodGroupData: [], //分组数据
				supplierList: [], //供应商数据
				goodsCode:'',
				goodsName:'',
				multipleSelection:[],
				checked:false
			}
		},
		created() {
			this.getGroupList();
			this.supplierGetList();
			this.getMeterialList();
		},
		methods: {
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getMeterialList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getMeterialList();
			},
			//添加素材
			addMeterail(item,type) {
				let params = {
					id: item.id||0,
					type: type
				}
				this.publicToDetailsR(params, '/material/addMaterial/addMaterial', this);
			},
			goodsFilter() {
				this.currentPage = 1;
				this.getMeterialList();
			},
			async getMeterialList() {
				try {
					this.loading = true
					let data = {
						pageNo: this.currentPage, // 略过数据
						pageSize: this.pageSize, // 取的数据
						begTime:this.addTimes ? this.addTimes[0]:'',
						endTime:this.addTimes ? this.addTimes[1]:'',
						goodsCode:this.goodsCode,
						goodsName:this.goodsName,
						groupId:this.groupId,
						supplierName:this.supplierId,
					}
					console.log("------",data)
					let result = await findAllMaterial(data)
					if (result.success) {
						this.Total = result.data.total;
						result.data.records.map(item=>{
							item.materialTypeStr = item.type == 1 ? '图文' : '视频';
							return item;
						})
						this.MeterialData = result.data.records || [];
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				} catch (err) {
					console.log(err)
				} finally {
					this.loading = false
				}
			},
			deleteChose(record,type) {
				if(type==2){
					if(this.multipleSelection.length==0){
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择要删除的素材'
						});
						return;
					}
				}
				this.$confirm('是否确认删除素材?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let list = [];
					if(type==1){
						list.push(record.id);
					}else{
						this.multipleSelection.map(item=>{
							list.push(item.id);
							return item;
						})
					}
					let result = await delMaterial(list);
					if (result.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						setTimeout(() => {
							this.getMeterialList();
						}, 500)
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					// this.currentPage = 1;
				})
			},
			// 获取分组列表数据
			async getGroupList() {
				try {
					this.loading = true
					let data = {
						pageNo: 0, // 略过数据
						pageSize: 10000, // 取的数据
					}
					let result = await productGroupListData(data);
					this.goodGroupData = result.data.records || [];

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			//初始化供应商数据
			async supplierGetList() {
				try {
					let data = {
						pageNo: 0,
						pageSize: 10000,
					};
					let res = await supplierGetList(data);
					this.supplierList = res.data.records;
				} catch (e) {
					console.log(e)
				}
			},
			getRowKeys (row) {
				return row.id;
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				var isAll = val.length == this.MeterialData.length ? true : false;
				if (isAll) {
					this.checked = true;
				} else {
					this.checked = false;
				}
			},
			tabCheckedChange() {
				this.$refs['tab'].toggleAllSelection();
			},
		}
	}
</script>

<style lang="less">
	.MeterialBox {
		font-size: 15px;

		.MeterialContain {
			background: #fff;
			padding: 10px;
		}
	}
</style>
